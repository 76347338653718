import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Let's be friends`}</h2>
    <p style={{
      "textAlign": "center"
    }}><a href="https://www.linkedin.com/in/uhd/">Linkedin</a></p>
    <p style={{
      "textAlign": "center"
    }}><p id="email-tag"></p></p>
    <p style={{
      "textAlign": "center"
    }}>Discord : @uhdren</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      