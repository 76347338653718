import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projets`}</h2>
    <ProjectCard title="Showreel & animation" link="https://isofej.notion.site/Animation-50495c1aa9354a4c95990f21bf241464" bg="#7771" mdxType="ProjectCard">
  🎞️
    </ProjectCard>
    <ProjectCard title="Illustration" link="https://isofej.notion.site/Illustration-19b26f72c967432f839126d7ccc55de5" bg="#7771" mdxType="ProjectCard">
  🖍️
    </ProjectCard>
    <ProjectCard title="Plastic arts" link="https://isofej.notion.site/Art-plastique-f6d911680b6f4b2fba7f9b508d36e155" bg="#7771" mdxType="ProjectCard">
  ✂️
    </ProjectCard>
    <ProjectCard title="Concept art" link="https://isofej.notion.site/Concept-art-7094415402c6455ebab4c66f7d9b5506" bg="#7771" mdxType="ProjectCard">
  🖼️
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      